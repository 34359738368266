<template>
  <nav>
      <ul class="nav navbar-nav align-items-start">
        <li class="nav-item">
            <a class="nav-link" href="#"></a>
        </li>
        <li class="text-light nav-item">
            <img src="~@/assets/rec_flag.png"/>
            <h3 class="navbar-text">為孩子帶來不一樣的學習體驗</h3>
        </li>  

    </ul>
    
  </nav>
  <b-form inline @submit="onSearch">
      <label class="mr-sm-2" for="inline-form-select-teacher">導師:</label>
      <b-form-select
          @change="onTeacherChange($event)"
          v-model="selectedTeacher"
          id="inline-form-select-teacher"
          class="mb-2 mr-sm-2 mb-sm-0"
          :options="teachers"
      />
      <label class="mr-sm-2" for="inline-form-custom-select-pref">學生:</label>
      <b-form-select
          v-model="selectedStudent"
          id="inline-form-custom-select-pref"
          class="mb-2 mr-sm-2 mb-sm-0"
          :options="students"
      />
      <label class="mr-sm-2" for="student-id-input">學生編號:</label>
      <input
          id="student-id-input"
          class="mb-2 mr-sm-2 mb-sm-0"
          v-model="searchByStudentId"
          placeholder="Enter student number"
      />
      <b-button variant="primary" type="submit">Search</b-button>
  </b-form>
  <div v-if="groupedSessions &&!groupedSessions.length">No sessions found</div>
  <div v-for="(sessions, studentIndex) in groupedSessions" v-bind:id="'student_' + studentIndex" v-bind:key="sessions[0].studentId" class="row">
    <div class="container mt-4">


      <div class="container text-left">
        <h2>{{sessions[0].studentName}}</h2>
        <div class="pt-2"><h5>語言範例精選</h5><span v-for="word in words.concat(sessions[0].longestWords)" v-bind:key="word" class="word-badge badge text-left badge-secondary mt-3">{{word}}</span>
        </div>
        <div class="container mt-2">
        <!-- 
        <h5>AI語言分析</h5>
        <div class="row">
          <div class="col-sm-3" v-for="entity in sessions[0].nlpEntities" v-bind:key="entity">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">{{entity.name}}</h5>
                <p class="card-text">Salience: {{entity.salience}}</p>
                <a href="#" class="btn btn-primary">{{entity.type}}</a>
              </div>
            </div>
          </div>
        </div>
        -->
        </div>
       </div>
       <div class="mt-3">
        <div class="container text-left">
          <div>
            <div class="row">
                <div class="col-sm-10">
                    <a class="btn btn-primary" v-bind:href="'#/comments/' + sessions[0].studentEmail + '?teacher=' + selectedTeacher" target="_blank">學生反饋</a>
                </div>
            </div>
          </div>
       </div> 
       <hr/> 
      </div>
    </div>

  </div>

     
<!-- Footer -->
<footer class="page-footer font-small blue">

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">Powered by iSpeech AI Speech & Language Technology © 2023
  </div>
  <!-- Copyright -->

</footer>
<!-- Footer -->

</template>
<style>
.big-lightbulb {
    font-size: 1.4em;
}
.focus-point-badge {
  margin: 0.2em;
  background-color: #c3d4e4;
  color: #212529;
  font-size: 0.9em;
   padding: 0.5em;
   border-radius: 2px;
}
.word-badge {
  background-color: #c3d4e4;
  white-space: normal;
  color: #212529;
  font-size: 1.2em;
  line-height: 1.5rem;
   padding: 0.6em;
   margin-left: 0.5em;
    border-radius: 2px;
}
.register-btn {
    position: absolute; 
    bottom: 10px;
    right: 10px;
}
.btn-primary {
    background-color: #42c2d5;
    border-color: #42c2d5;
}
</style>

<script>
import { computed, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { db, storage } from '@/firebase';
import { getDoc, getDocs, collection, query, doc, where } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import storageHelper from 'storage-helper';

export default {
 data() { 
   return {
     classes: [],
     maxConfidenceOf5And6: 0,
     words: [],
     longestWords: [],
     groupedSessions: null,
     teachers: [],
     teachersData: [],
     userName: "",
     emailAdress: "",
     searchByStudentId: "",
     selectedStudent: null,
     selectedTeacher: null,
     sessionLanguage: "",
     students: [{ value: null, text: 'Please select a student' }],
     studentName: "",
     teacherName: "",
     teacherImage: "",
     audioUrl: "",
     audioPlayer: null,
     audioPlaying: false,
     nlpEntities: []
   }
 },

 onSubmitProfileForm(data) {
 console.log('data')
 console.log(data)
      this.$vbsModal.close();
    },
beforeMount: async function() {
   /*     this.$vbsModal.open({
        content: EnterPasswordComponent,
        size: ModalSize.LARGE,
        staticBackdrop: true,
        contentProps: {
          emailAdress: this.email,
          privateAccount: this.private,
          userName: this.username,
          staticBackdrop: this.staticBackdrop,
          center: this.centered,
        },
        contentEmits: {
          onUpdate: this.onSubmitProfileForm,
        },
        center: true,
        backgroundScrolling: true,
      });
      */

},
methods: {
    onTeacherChange:  function(event) {
      const studentNames = this.teachersData[event].students;
      this.students = [...[{ value: null, text: 'Please select a student' }], ...studentNames.sort()]
    },
    playAudio(url) {
     if(url) {
        if (!this.audioPlayer) { 
            this.audioPlayer = new Audio(url);
            this.audioPlayer.addEventListener("ended", this.audioStoppedPlaying, false);
        }
        this.audioPlayer.play();

        this.audioPlaying = true;
     }
    },
    pauseAudio() {
        this.audioPlayer.pause();
        this.audioPlaying = false;
    },
    onSearch: async function() {
      let studentEmail;
      // Search by Student No
      if(this.searchByStudentId) {
        const studentRef = doc(db, "students", this.searchByStudentId);
        const studentDocSnap = await getDoc(studentRef);
        if (studentDocSnap.exists()) {
          const studentData = studentDocSnap.data();
          studentEmail = studentData.email;
        }
        this.searchByStudentId = null;
      }
      // Find the students by student email or teacher 
      const classRecordsQuery = studentEmail
                    ? query(collection(db, "class_records"), where("studentEmail", "==", studentEmail))
                    : query(collection(db, "class_records"), where("teacherName", "==", this.selectedTeacher));
      const sessionsSnap = await getDocs(classRecordsQuery);
      const groupedSessions = [];
      const sessions = [];

      sessionsSnap.forEach((doc) => {
        const session = doc.data();
        if(session.studentId) {
          session.studentId = session.studentId.replaceAll(/\s/g, ''); 
        }
        else session.studentId = null;
        sessions.push(session);
      });

      const sessionsGroupedByStudentId = sessions.reduce((group, arr) => {   
        const { studentId } = arr;
        group[studentId] = group[studentId] ?? [];
        group[studentId].push(arr);
        return group;
      },{});

      Object.entries(sessionsGroupedByStudentId).forEach(sessions => {
        console.log('grouped');
        console.log(sessions)
        const deduplicatedSessions = sessions[1].filter((value, index, self) =>
          index === self.findIndex((t) => (
            (t.date === value.date) && (t.teacherName === t.teacherName)
          ))
        );
        const sortedSessions = deduplicatedSessions.sort((a,b)=>{
          return new Date(a.date) - new Date(b.date);
        });

        // Replace the longest words of the 1st session with the longest words of all the sessions
        if(sortedSessions.length) { 
          const longestWordForAllSessions = sessions[1].map(session=>session.longestWords).flat().sort((a,b)=>(a.length < b.length) ? -1 : 1).slice(-1);
          sortedSessions[0].longestWords = longestWordForAllSessions;
        }

        groupedSessions.push(sortedSessions);
      });
      console.log('grouped sessions')
      console.log(groupedSessions);
      this.groupedSessions = groupedSessions;
      


    },
    audioStoppedPlaying() {
        this.audioPlaying = false;
    },
    analyzeText(words) {
        const request = {
          method: "POST",
          mode: "cors",
          cache: "default",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ inputText: words.toString() })
        };
        return fetch('http://localhost:3000/NLP/',request).then(response => response.json())
            .then(data => {
                console.log('finished NLP');
                console.log(data.entities.entities);
                this.nlpEntities = data.entities.entities;
            })
        .catch((error) => {
          console.error('Error:', error);
        });
    }

},
 mounted: async function() {    
      const route = useRoute();
      // Check password
      if (storageHelper.getItem('admin-password') !== 'Aei53ger%') {
        this.$router.push('/login?q=' + route.path + '&a=true');
      }

      // Get all teachers
      const teachersDocRef = doc(db, "enumerations", "teachers");
      const teachersDocSnap = await getDoc(teachersDocRef);

      if (teachersDocSnap.exists()) {
        this.teachersData = teachersDocSnap.data();

        const teacherNames = [];
        for (const teacher in this.teachersData) {
          teacherNames.push(teacher);
        }
        this.teachers = [...[{ value: null, text: 'Please select a teacher' }], ...teacherNames.sort()];
      }

      const results = reactive({ data: {}, classData: [] });
      const resultId = computed(()=> route.params.id) 
  
      console.log(`lifecycle method to get results for: ${resultId.value}`)
      const docRef = doc(db, "class_records", route.params.id);
      
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());

        results.data = docSnap.data()

        this.words = results.data.otherWords;
        this.longestWords = results.data.longestWords;
        // The following line is for using Google NLP
        //this.analyzeText(this.words);
        this.sessionLanguage = results.data.sessionLanguage;
        this.studentName = results.data.studentName;
        this.teacherImage = results.data.teacherImage;
        this.teacherName = results.data.teacherName;
        this.teacherComments = results.data.teacherComments;
        this.otherInfo = results.data.otherInfo;
        
        getDownloadURL(ref(storage, results.data.audioRef)).then(url=>{
            this.audioUrl = url;         
        });

        const confidences = results.data.levelPrediction;

        const class5confidence = confidences.filter((x)=>{ return x.label == "5"})?.[0].score;
        const class6confidence = confidences.filter((x)=>{ return x.label == "6"})?.[0].score;

        const sortedConfidences = confidences.filter((x)=>{ return (Number(x.label) > 0) && (Number(x.label) < 5); }).sort((x)=>{ return x.score});
        this.maxConfidenceOf5And6 = Math.round(Math.max(class5confidence, class6confidence) * 100);

        if (class5confidence > 0.7 || class6confidence > 0.7) {
          console.log('confidence high')

          
        }
        else {
          // Only display the top 2
          const courseIds = [sortedConfidences[0].label, sortedConfidences[1].label]

          const classQuery = query(collection(db, "classes"), where("courseId", "in", courseIds));
          const classSnap = await getDocs(classQuery);
          const classes = [];
          classSnap.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
           
            const classData = doc.data();
            classes.push({ ...sortedConfidences.filter((x)=>{ return x.label == classData.courseId})?.[0],
            ...classData });

          });
          this.classes = classes;

        }
       
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }


 },

}
</script>
