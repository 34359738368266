function getWordRangeFromAge(age: number) {
    if (age >= 12) return '500-550';
    if (age >= 11) return '450-500';
    if (age >= 10) return '400-450';
    if (age >= 9) return '350-400';
    if (age >= 8) return '300-350';
    if (age >= 7) return '250-300';
    if (age >= 6) return '200-250';
    if (age >= 5) return '150-200';
    if (age >= 4) return '100-150';
    return '50-100';
}

const formatTime = (seconds: number): string => [seconds / 60, seconds % 60].map((v) => `0${Math.floor(v)}`.slice(-2)).join(':')

export { getWordRangeFromAge, formatTime }